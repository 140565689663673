* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --offwhite: #EDF5E1;
}

.content {
  background-color: #05386B;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro {
  color: var(--offwhite);
  font-family: 'Lora', serif;
  font-size: 42px;

}

@media (max-width: 768px) {
  .intro {
    margin: 16px;
  }
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.link-container {
  margin: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon {
  height: 48px;
  width: 48px;
  margin: 16px;
}